import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { AppConfig } from '@dougs/core/config';
import { URL } from '@dougs/core/routing';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private readonly window: Window,
    private readonly http: HttpClient,
    private readonly intercom: Intercom,
  ) {}

  goToLogout(): void {
    this.intercom.shutdown();
    this.http
      .get(`${AppConfig.settings.legacyApiServerUrl}/auth/api/logout`)
      .subscribe(() => (this.window.location.href = URL.HOME));
  }
}
