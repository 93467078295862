import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class CanAccessSigninGuard {
  private readonly rootUrl: UrlTree;

  constructor(
    private readonly userStateService: UserStateService,
    private readonly router: Router,
  ) {
    this.rootUrl = this.router.parseUrl('/');
  }

  async canActivate(): Promise<boolean | UrlTree> {
    const isLoggedIn: boolean = await this.userStateService.refreshLoggedInUser();

    if (isLoggedIn) {
      return this.rootUrl;
    }

    return true;
  }
}
